import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

const Features = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="Features">
            
        </WebsiteLayout>
    </main>
  )
}

export default Features;